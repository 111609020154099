import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActionType,
  CommanLineElementPosition,
  CommandLinePosition,
  SideSheetBackdropType,
  SideSheetButtonEvent,
  SideSheetButtonListener,
  SideSheetCheckboxEvent,
  SideSheetCheckboxListener,
  SideSheetComponentLoadListener,
  SideSheetConfig,
  SideSheetContent,
  SideSheetDropdownEvent,
  SideSheetDropdownListener,
  SideSheetEvent,
  SideSheetService,
  SideSheetTooltipButtonEvent,
  SideSheetTooltipButtonListener,
} from 'c4p-portal-util';
import { SideSheetExampleComponent } from './side-sheet-example.component';
import { SIDE_SHEET_CONFIG, SIDE_SHEET_CONTENT } from '../../../models/side-sheet/side-sheet-token';

@Component({
  selector: 'app-dynamic-component',
  templateUrl: './dynamic-example.component.html',
  styleUrls: ['./dynamic-example.component.scss']
})
export class DynamicComponent
  implements
    SideSheetComponentLoadListener,
    SideSheetButtonListener,
    SideSheetCheckboxListener,
    SideSheetDropdownListener,
    SideSheetTooltipButtonListener,
    OnInit,
    OnDestroy
{
  eventText = 'No event happened';

  constructor(
    @Inject(SIDE_SHEET_CONTENT) public sideSheetContent: SideSheetContent,
    @Inject(SIDE_SHEET_CONFIG) public sideSheetConfig: SideSheetConfig,
    private sideSheetService: SideSheetService
  ) {
    console.log("DynamicComponent constructor");
  }


  onLoadComponent(event: SideSheetEvent) {
    console.log("DynamicComponent loaded");
    
  }

  onUnloadComponent(event: SideSheetEvent) {
    console.log("DynamicComponent unloaded");
  }

  ngOnDestroy(): void {
    console.log("DynamicComponent ngOnDestroy");
  }

  ngOnInit() {
    console.log("DynamicComponent ngOnInit");
  }

  toggleBackdrop() {
    this.sideSheetConfig.hasBackdrop = !this.sideSheetConfig.hasBackdrop;
  }

  toggleBackdropType() {
    this.sideSheetConfig.backdropType = this.sideSheetConfig?.backdropType === SideSheetBackdropType.OPAQUE ? SideSheetBackdropType.TRANSPARENT : SideSheetBackdropType.OPAQUE; 

  }

  toggleDisableCloseOnBackdropClick() {
    this.sideSheetConfig.disableCloseOnBackdropClick = !this.sideSheetConfig.disableCloseOnBackdropClick;
  }

  toggleFirstButtonPosition() {
    if (this.sideSheetContent?.buttons?.[0])
    this.sideSheetContent.buttons[0].position = this.sideSheetContent.buttons[0].position === CommanLineElementPosition.LEFT ? CommanLineElementPosition.RIGHT : CommanLineElementPosition.LEFT;
  }

  toggleTitleBar(){
    this.sideSheetConfig.titleBarVisible = !this.sideSheetConfig.titleBarVisible;
  }

  toggleTitleBarCloseButton(){
    this.sideSheetConfig.titleBarCloseVisible = !this.sideSheetConfig.titleBarCloseVisible;
  }

  toggleTitleBarFullscreenButton(){
    this.sideSheetConfig.titleBarFullscreenVisible = !this.sideSheetConfig.titleBarFullscreenVisible;
  }

  toggleBreadcrumb(){
    this.sideSheetConfig.breadcrumbVisible = !this.sideSheetConfig.breadcrumbVisible;
  }

  toggleBreadcrumbCloseButton(){
    this.sideSheetConfig.breadcrumbCloseVisible = !this.sideSheetConfig.breadcrumbCloseVisible;
  }

  toggleBreadcrumbFullscreenButton(){
    this.sideSheetConfig.breadcrumbFullscreenVisible = !this.sideSheetConfig.breadcrumbFullscreenVisible;
  }

  toggleCommandLine(){
    this.sideSheetConfig.commandLineVisible = !this.sideSheetConfig.commandLineVisible;
  }

  toggleCommandlinePosition() {
    this.sideSheetConfig.commandLinePosition = this.sideSheetConfig?.commandLinePosition === CommandLinePosition.BOTTOM ? CommandLinePosition.TOP : CommandLinePosition.BOTTOM; 
  }

  closeComponent() {
    this.sideSheetService.closeComponent({surname: "<dynamic"});
  }

  loadComponent() {
    const content: SideSheetContent = new SideSheetContent();
    content.title = 'Example Component';
    content.subTitle = 'Example Subtitle';
    content.buttons = [];
    content.data = {sender: "Dynamic page", receviver: "Example component", description: "I am sending this event from dynamic to example component"};

    const sideSheetRef = this.sideSheetService.loadComponent(SideSheetExampleComponent, content, {
      commandLineVisible: false,
    });

    sideSheetRef.afterClosed().subscribe((data)=>{
      console.log("Dynamic get from exm ", data);
    });
  }

  onClickButton(event: SideSheetButtonEvent) {
    this.eventText = event?.button?.name + ' is clicked';

    
    if (event?.button?.actionType === ActionType.DELETE) {
      event.button.disabled = true;
      const action = this.sideSheetService.createConfirmAction(event?.button);
      this.sideSheetService.openTooltip(action);
    }
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      const action = this.sideSheetService.createConfirmAction(event?.button, "general.labels.Confirm", "There are some changes have not been saved yet. Are you sure to close dialog?");
      this.sideSheetService.openTooltip(action);
    }

    if (event?.button?.actionType === ActionType.CLOSE) {
      this.sideSheetService.closeComponent();
    }
  }

  onCheckboxSelected(event: SideSheetCheckboxEvent) {
    this.eventText = event?.checkbox?.label + ' check changed';
  }

  onDropdownSelected(event: SideSheetDropdownEvent) {
    this.eventText = event?.selectBoxes?.label + ' is selected';
  }

  onClickTooltipButton(event: SideSheetTooltipButtonEvent) {
    this.eventText = event?.button?.name + ' is clicked with confirmation = ' + event?.confirmed;

    if (event?.button?.actionType === ActionType.DELETE) {
      event.button.disabled = false;
    }
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE && event?.confirmed) {
      this.sideSheetService.closeSideSheet();
    }
    
  }
}
